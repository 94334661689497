<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Tyre Change Form</strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
             
             <CInput
                label="Mechanic Name"
                v-model="changeForm.mechanicName "
                horizontal
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
             <CInput
                label="Date"
                v-model="changeForm.date "
                horizontal
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
             <CInput
                label="Vehicle No"
                v-model="changeForm.vehicleNo "
                horizontal
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
             <CInput
                label="Job Code"
                v-model="changeForm.jobCode "
                horizontal
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
               <div v-if='changeForm.otherTyreSize!=null'>
             <CInput
                label="Other Tyre Size"
                v-model="changeForm.otherTyreSize"
                horizontal
                placeholder="--"
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
               </div>
             <div v-if='changeForm.company!=null'>
              <CInput
                label="Company"
                v-model="changeForm.company "
                horizontal
                placeholder="--"
                :disabled="mode.viewMode"
                 :invalid-feedback="'--'"
             />
             </div>
             <CInput
                label="Odo meter"
                v-model="changeForm.odometer "
                horizontal
                :disabled="mode.viewMode"
               
             />
             <CInput
                label="Engine Hours"
                v-model="changeForm.engineHours "
                horizontal
                :disabled="mode.viewMode"
                
             />
           <CInput
                label="Start Time"
                v-model="changeForm.startTime "
                horizontal
                :disabled="mode.viewMode"
             />
             <CInput
                label="Completed Time"
                v-model="changeForm.completedTime "
                horizontal
                :disabled="mode.viewMode"
             />
             <CInput
                label="Driver Name"
                v-model="changeForm.driverName "
                horizontal
                :disabled="mode.viewMode"
             />
              <CInput
                label="Driver No"
                v-model="changeForm.driverNricNo "
                horizontal
                :disabled="mode.viewMode"
             />
              <CInput
                label="Type Position"
                v-model="changeForm.typePosition "
                horizontal
                :disabled="mode.viewMode"
             />
              <CInput
                label="Acknowledged"
                v-model="changeForm.acknowledged "
                horizontal
                :disabled="mode.viewMode"
             />
            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
        <CCard>
           <CCardHeader>
            <strong>Tyre Removal Or Installed</strong>
          </CCardHeader>
  <CCardBody>
   
    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="true">
       
        <template #tyreRemovalOrInstall-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'tyreRemovalOrInstall',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Removal</option>
            <option value="2">Installed</option>
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

 <template #tyreNewTypeName="{item}">
        <td class='font-weight-bold'
          v-if="item.tyreNewTypeName"
        >{{ item.tyreNewTypeName }}</td>
        <td v-else>--</td>
      </template>
      <!-- <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template> -->
  
       <template #tyreRemovalOrInstall="{ item }">
          <td class='font-weight-bold' v-if="item.tyreRemovalOrInstall == '1'">Removal</td>
          <td class='font-weight-bold' v-else>Installed</td>
         
        </template>
       <template #otherRemarks="{item}">
        <td class='font-weight-bold'
          v-if="item.otherRemarks"
        >{{ item.otherRemarks }}</td>
        <td v-else>--</td>
      </template>
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'TyreChangeForm', params: {tyreFormId: item.tyreFormId, mode: 'view'}}">View </CDropdownItem>
           
                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
 <CCardFooter align="right">
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
      </CCol>
    </CRow>
   
  </div>
</template>

<script>
const fields = [
  'index',
  { key: "tyreRemovalOrInstall", _classes: 'font-weight-bold', _style: "min-width:200px" },
 // { key: "tyrePositionId", _classes: 'font-weight-bold', _style: "min-width:100px" },
  //{ key: "tyreRemovalReasonId", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "tyreNewTypeName", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "tyreNumber", _classes: 'font-weight-bold', _style: "min-width:100px" },
    { key: "brand", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "pattern", _classes: 'font-weight-bold', _style: "min-width:200px" },
   {key: "otherRemarks", _classes: 'font-weight-bold', _style: "min-width:200px" },
 
  
];
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "TyreChangeForm",
  data() {
    return {
      mode:{
        viewMode:false,
      },
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
     tyreFormId:"",
   changeForm:{
     tyreFormId:"0",
     userId:'',
     locationId:'',
     company:'',
     mechanicName:'',
     date:'',
     vehicleNo:'',
     jobCode:'',
     tyreSizeId:'',
     otherTyreSize:'',
     odometer:'',
     engineHours:'',
     startTime:'',
     completedTime:'',
     driverName:'',
     driverNricNo:'',
     typePosition:'',
     acknowledged:'',
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    };
 },

   created() {  
            
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.tyreFormId = this.$route.params.tyreFormId;
                    this.getFormdata();
                    this.getTyreremovals();
            }
           
        },
         components: { datetime },
  methods: {
  
     cancel() {
      this.$router.push({ name: 'TyreChangeForms' })
    },
   

    
    getFormdata() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/tyre/changeForms/"+this.tyreFormId)
        .then((response) => response.json())
        .then( (data)  => (this.changeForm = data) );
        
    },
    getTyreremovals() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/tyre/removalOrInstalled/"+this.tyreFormId)
        .then((response) => response.json())
        .then( (data)  => (this.items = data) );
        
    },
   
  },
  mounted() {
    this.getTyreremovals();
    this.getFormdata();
   
  }
};
</script>
